<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :hide-selected="false"
    no-data-text="Нет результатов"
    :clearable="clearable"
    :error-messages="errors"
    :label="label"
    :disabled="disabled"
    :type="type"
    :counter="counter"
    :items="items"
  />
  <!--    :filter="() => true"-->
</template>

<script>
export default {
  name: "SelectField",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    items: {
      type: Array,
      required: true
    },

    type: {
      type: String
    },
    clearable: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {
      type: [String, Number, Boolean]
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
