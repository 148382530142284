<template>
  <v-select
    :value="value"
    :items="items"
    :label="label"
    :required="required"
    :disabled="disabled"
    @input="$emit('input', $event)"
    dense
  >
    <!-- КАСТОМИЗАЦИЯ ПОЛЕЙ -->
    <template v-slot:selection="{ item }">
      <div
        class="baseSelectField"
        :class="{ 'baseSelectField--disabled': disabled }"
      >
        {{ item.text }}
      </div>
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-content>
          <v-list-item-title>
            <div class="baseSelectField">{{ item.text }}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <!-- /КАСТОМИЗАЦИЯ ПОЛЕЙ -->
  </v-select>
</template>

<script>
export default {
  name: "BaseSelectField",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    items: {
      type: Array,
      required: true
    },

    type: {
      type: String
    },
    clearable: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {
      type: [String, Number, Boolean]
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    searchFunction: {
      default: false
    }
  }
};
</script>

<style>
.baseSelectField {
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: #515a6e;
  font-size: 12px;
}

.baseSelectField--disabled {
  color: grey;
  opacity: 0.6;
}
</style>
