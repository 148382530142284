<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:add_fail>
        <CreateFormLayout>
          <template v-slot:title__text>
            Массовое добавление карт
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <div class="instruction">
            <p class="instruction__title">Правила заполнения файла:</p>
            <div class="instruction__description">
              <ol>
                <li>
                  Данные должны быть записаны в один столбец.
                </li>
                <li>
                  Номер карты представляет собой набор из восьми цифр
                  разделённых между собой знаком-разделителем.
                </li>

                <li>Знак всегда ставится после первых трёх цифр.</li>
                <li>
                  Знаком-разделителем является точка. Другие разделители
                  недопустимы.
                </li>
                <li>
                  Пример заполнения
                  <a href="/img/images/documentations/upload_cards.png"
                    >по ссылке.</a
                  >
                </li>
              </ol>
            </div>
          </div>
          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationFileField
                  v-model="file"
                  :rules="'required'"
                  accept=".xlsx"
                  label="Выбрать файл"
                  validate-name="выбрать файл"
                />
                <ValidationAutocompleteField
                  :search-function="objectSearch"
                  label="Объект"
                  validate-name="объект"
                  v-model="object_file"
                  :disabled="!!kindergartenId"
                />
              </FormInputBlock>

              <FormInputBlock>
                <div class="d-flex flex-column " style="overflow: inherit">
                  <ValidationSelectField
                    class="validation-select-field"
                    v-model="disable_activation_period_expires_upload"
                    :disabled="!sourceCreateObject && currentRole == 60"
                    :items="[
                      { text: 'Да', value: false },
                      { text: 'Нет', value: true }
                    ]"
                    label="Мгновенная работа карт"
                    validate-name="Мгновенная работа карт"
                  ></ValidationSelectField>
                  <div
                    v-if="!disable_activation_period_expires_upload"
                    class="mt-2 v-messages__message"
                    style="color: #515A6E; font-size: 12px"
                  >
                    Карты начинают действовать на объекте после добавления в
                    базу детского сада
                  </div>
                  <div
                    v-else
                    class="mt-2 v-messages__message"
                    style="color: #515A6E; font-size: 12px"
                  >
                    Карты начинают действовать на объекте
                    <span style="text-decoration: underline"
                      >после регистрации пользователем</span
                    >
                  </div>
                </div>
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(uploadCards)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
      <template v-slot:manual_input>
        <CreateFormLayout>
          <template v-slot:title__text>
            Добавление карт
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationAutocompleteField
                  :search-function="objectSearch"
                  label="Объект"
                  validate-name="объект"
                  rules="required"
                  v-model="object_manual"
                  :disabled="!!kindergartenId"
                />
              </FormInputBlock>
              <FormInputBlock>
                <ValidationInputField
                  placeholder="###.#####"
                  mask="###.#####"
                  v-model="cardNumber"
                  @input="$event.length >= 9 ? addNumber($event) : ''"
                  label="Номер карты"
                  validate-name="номер карты"
                />
              </FormInputBlock>

              <FormInputBlock>
                <div class="d-flex flex-column " style="overflow: inherit">
                  <ValidationSelectField
                    class="validation-select-field"
                    v-model="disable_activation_period_expires"
                    :disabled="!sourceCreateObject && currentRole == 60"
                    :items="[
                      { text: 'Да', value: false },
                      { text: 'Нет', value: true }
                    ]"
                    label="Мгновенная работа карт"
                    validate-name="Мгновенная работа карт"
                  ></ValidationSelectField>
                  <div
                    v-if="!disable_activation_period_expires"
                    class="mt-2 v-messages__message"
                    style="color: #515A6E; font-size: 12px"
                  >
                    Карты начинают действовать на объекте после добавления в
                    базу детского сада
                  </div>
                  <div
                    v-else
                    class="mt-2 v-messages__message"
                    style="color: #515A6E; font-size: 12px"
                  >
                    Карты начинают действовать на объекте
                    <span style="text-decoration: underline"
                      >после регистрации пользователем</span
                    >
                  </div>
                </div>
              </FormInputBlock>

              <table class="table table_overflow_hidden table-physical_cards">
                <thead>
                  <tr>
                    <th>Номер карты</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(number, index) in cardNumbers"
                    class="item"
                    :key="index"
                  >
                    <td>{{ number }}</td>
                    <td class="btn-delete-wrap">
                      <button
                        @click.stop="deleteNumber(index)"
                        class="form__btn-delete"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(createCards)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
    </SlideLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";

import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getObjectsRequestFromChildren } from "@/helpers/api/objects";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "CreateCardPage",
  components: {
    ValidationAutocompleteField,
    ValidationFileField,
    ValidationInputField,
    ValidationSelectField,
    FormInputBlock,
    CreateFormLayout,
    SlideLayout,
    MainLayout
  },
  props: {
    kindergartenId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      loading: false,
      objectSearch: async value => {
        return (
          await getObjectsRequestFromChildren({
            query: { search: value, type: "kindergarten" }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      slideTabs: [
        {
          text: "Добавить вручную",
          value: "manual_input",
          icon: null,
          count: null
        },
        {
          text: "Загрузить Файл XLSX",
          value: "add_fail",
          icon: null,
          count: null
        }
      ]
    };
  },
  computed: {
    sourceCreateObject() {
      let result =
        (this.$store?.getters?.getDealerInfo?.results?.created_at + 5184000) *
        1000;
      let now = Date.now();
      let res = now < result;

      return res;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    file: {
      get() {
        return this.$store.getters.getCreateCardFileForm.file;
      },
      set(newValue) {
        this.$store.commit("setCreateCardFileForm", {
          fieldName: "file",
          value: newValue
        });
      }
    },
    object_file: {
      get() {
        return this.$store.getters.getCreateCardFileForm.object;
      },
      set(newValue) {
        this.$store.commit("setCreateCardFileForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    object_manual: {
      get() {
        return this.$store.getters.getCreateCardManualForm.object;
      },
      set(newValue) {
        this.$store.commit("setCreateCardManualForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    cardNumber: {
      get() {
        return this.$store.getters.getCreateCardManualForm.cardNumber;
      },
      set(newValue) {
        this.$store.commit("setCreateCardManualForm", {
          fieldName: "cardNumber",
          value: newValue
        });
      }
    },
    cardNumbers: {
      get() {
        return this.$store.getters.getCreateCardManualForm.cardNumbers;
      },
      set(newValue) {
        this.$store.commit("setCreateCardManualForm", {
          fieldName: "cardNumbers",
          value: newValue
        });
      }
    },
    disable_activation_period_expires: {
      get() {
        if (!this.sourceCreateObject) {
          this.$store.commit("setCreateCardManualForm", {
            fieldName: "disable_activation_period_expires",
            value: true
          });
          return true;
        } else
          return this.$store.getters.getCreateCardManualForm
            .disable_activation_period_expires;
      },
      set(newValue) {
        this.$store.commit("setCreateCardManualForm", {
          fieldName: "disable_activation_period_expires",
          value: newValue
        });
      }
    },
    disable_activation_period_expires_upload: {
      get() {
        if (!this.sourceCreateObject) {
          this.$store.commit("setCreateCardFileForm", {
            fieldName: "disable_activation_period_expires",
            value: true
          });
          return true;
        } else
          return this.$store.getters.getCreateCardFileForm
            .disable_activation_period_expires;
      },
      set(newValue) {
        this.$store.commit("setCreateCardFileForm", {
          fieldName: "disable_activation_period_expires",
          value: newValue
        });
      }
    }
  },

  mounted() {
    if (this.kindergartenId) {
      getKindergartenByIdRequest({ id: this.kindergartenId }).then(response => {
        this.object_file = response.data.object.id;
        this.object_manual = response.data.object.id;
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateCardForm");
  },
  methods: {
    addNumber(number) {
      this.$store.commit("addCardNumber", { number });
      this.cardNumber = null;
    },
    deleteNumber(id) {
      this.$store.commit("deleteCardNumber", { id });
    },
    uploadCards() {
      this.loading = true;
      this.$store
        .dispatch("uploadCards")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.upload(successMessageDictionary.cards)
          );
        })
        .finally(() => (this.loading = false));
    },
    createCards() {
      this.loading = true;
      this.$store
        .dispatch("createCards")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.card)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
